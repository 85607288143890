<template>
    <div>
      <div v-if="type == 'importExport' && details">
            <v-tooltip top v-if="details.isImport">
                <template v-slot:activator="{ on }">
                    <v-btn icon outlined x-small class="mx-1" v-on="on" color="green"><v-icon
                            small>arrow_downward</v-icon></v-btn>
                </template>
                <span>Import</span>
            </v-tooltip>

            <v-tooltip top v-if="details.isExport">
                <template v-slot:activator="{ on }">
                    <v-btn icon outlined x-small class="mx-1" v-on="on" color="deep-orange"><v-icon
                            small>arrow_upward</v-icon></v-btn>
                </template>
                <span>Export</span>
            </v-tooltip>
      </div>
      <div v-if="type == 'chargeLocation' && details">
        <v-tooltip top v-if="details.isOrigin">
                        <template v-slot:activator="{ on }">
                            <v-btn icon outlined x-small class="mx-1" v-on="on" color="blue-grey"><v-icon
                                    small>location_on</v-icon></v-btn>
                        </template>
                        <span>Origin</span>
                    </v-tooltip>
    
                    <v-tooltip top v-if="details.isFreight">
                        <template v-slot:activator="{ on }">
                            <v-btn icon outlined x-small class="mx-1" v-on="on" color="blue-grey"><v-icon
                                    small>local_shipping</v-icon></v-btn>
                        </template>
                        <span>Freight</span>
                    </v-tooltip>
    
                    <v-tooltip top v-if="details.isDestination">
                        <template v-slot:activator="{ on }">
                            <v-btn icon outlined x-small class="mx-1" v-on="on" color="blue-grey"><v-icon
                                    small>where_to_vote</v-icon></v-btn>
                        </template>
                        <span>Destination</span>
                    </v-tooltip>
      </div>
      <div v-if="type == 'mode' && details" class="text-center">
        <v-tooltip top v-if="details.isSea">
            <template v-slot:activator="{ on }">
                <v-btn icon outlined x-small class="mx-1" v-on="on" color="blue"><v-icon
                        small>directions_boat</v-icon></v-btn>
            </template>
            <span>Sea</span>
        </v-tooltip>

        <v-tooltip top v-if="details.isAir">
            <template v-slot:activator="{ on }">
                <v-btn icon outlined x-small class="mx-1" v-on="on" color="teal"><v-icon
                        small>flight</v-icon></v-btn>
            </template>
            <span>Air</span>
        </v-tooltip>

        <v-tooltip top v-if="details.isRail">
            <template v-slot:activator="{ on }">
                <v-btn icon outlined x-small class="mx-1" v-on="on" color="indigo"><v-icon
                        small>train</v-icon></v-btn>
            </template>
            <span>Rail</span>
        </v-tooltip>

        <v-tooltip top v-if="details.isRoad">
            <template v-slot:activator="{ on }">
                <v-btn icon outlined x-small class="mx-1" v-on="on" color="purple"><v-icon
                        small>local_shipping</v-icon></v-btn>
            </template>
            <span>Road</span>
        </v-tooltip>
      </div>
      <div v-if="type == 'loadType' && details" class="text-center">
        <v-tooltip top v-if="details.fullContainerLoad">
            <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="mx-1" v-on="on" color="cyan darken-2">FL</v-chip>
            </template>
            <span>Full Load </span>
        </v-tooltip>

        <v-tooltip top v-if="details.lessContainerLoad">
            <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="mx-1" v-on="on" color="pink accent-2">LL</v-chip>
            </template>
            <span>Less than Load</span>
        </v-tooltip>
      </div>
      <div v-if="type == 'region' && details && regions" class="text-center">
        <v-tooltip top v-if="!details.allOriginRegion &&formattedRegions('Origin', details)">
            <template v-slot:activator="{ on }">
                <v-btn color="green" outlined x-small class="mx-1" v-on="on" icon>{{ formattedRegions('Origin',
                    details) }}</v-btn>
            </template>
            <span>{{ formattedRegions('Origin', details) }} Origin Regions </span>
        </v-tooltip>

        <v-tooltip top v-if="!details.allDestinationRegion && formattedRegions('Destination', details)">
            <template v-slot:activator="{ on }">
                <v-btn color="deep-orange" outlined x-small class="mx-1" v-on="on" icon>{{ formattedRegions('Destination',
                    details) }}</v-btn>
            </template>
            <span>{{ formattedRegions('Destination', details) }} Destination Regions </span>
        </v-tooltip>
      </div>
      <div v-if="type == 'country' && details && countries" class="text-center">
        <v-tooltip top v-if="!details.allOriginCountry && formattedCountries('Origin', details)">
            <template v-slot:activator="{ on }">
                <v-btn color="green" outlined x-small class="mx-1" v-on="on" icon>{{ formattedCountries('Origin',
                    details) }}</v-btn>
            </template>
            <span>{{ formattedCountries('Origin', details) }} Origin Countries </span>
        </v-tooltip>
        <v-tooltip top v-if="!details.allDestinationCountry && formattedCountries('Destination', details)">
            <template v-slot:activator="{ on }">
                <v-btn color="deep-orange" outlined x-small class="mx-1" v-on="on" icon>{{ formattedCountries('Destination',
                    details) }}</v-btn>
            </template>
            <span>{{ formattedCountries('Destination', details) }} Destination Countries </span>
        </v-tooltip>
      </div>
      <div v-if="type == 'createdBy' && details" class="text-center">
        <v-tooltip v-if="Object.keys(details).length" top>
            <template v-slot:activator="{ on }">
                <v-avatar size="20" :color="details.avatar ? 'white' : 'secondary' " left>
                  <v-img v-if="details.avatar" :src="details.avatar" contain></v-img>
                  <h3 v-else style="color: white">
                    {{ details.firstname.charAt(0) }}
                  </h3>
                </v-avatar>
                <span v-on="on" class="ml-2">{{ details.firstname }}</span>
            </template>
            <span>{{ `${details.firstname} ${details.surname}` }}</span>
        </v-tooltip>
        <v-tooltip v-else top>
            <template v-slot:activator="{ on }">
                <v-icon color="yellow" x-small>admin_panel_settings</v-icon>
                <span v-on="on" class="ml-2">System</span>
            </template>
            <span>System Generated</span>
        </v-tooltip>
    </div>
      <div v-if="type == 'createdBy' && !details" class="text-center">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-avatar size="20" color="white" left>
                    <v-icon color="primary" x-small>admin_panel_settings</v-icon>
                </v-avatar>
                <span v-on="on" class="ml-2">System</span>
            </template>
            <span>System Generated</span>
        </v-tooltip>
    </div>
      <div v-if="type == 'updatedBy' && details && Object.keys(details).length" class="text-center">
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-avatar size="20" :color="details.avatar ? 'white' : 'secondary' " left>
                  <v-img v-if="details.avatar" :src="details.avatar" contain></v-img>
                  <h3 v-else style="color: white">
                    {{ details.firstname.charAt(0) }}
                  </h3>
                </v-avatar>
                <span v-on="on" class="ml-2">{{ details.firstname }}</span>
            </template>
            <span>{{ `${details.firstname} ${details.surname}` }}</span>
        </v-tooltip>
    </div>

    </div>
  </template>
  <script>
  export default {
    data: () => ({
      type: null,
      details: null,
      regions: null,
      countries: null
    }),
    mounted() {
        if(this.params.type) {
            this.type = this.params.type
            this.details = this.params.details
            if (this.type == 'region') {
                this.regions = this.params.regions
            } else if(this.type == 'country') {
                this.countries = this.params.countries
            }
        }
    //   if (typeof this.params.value == "object") {
    //     this.item = null;
    //     this.pivot = this.params.value.value;
    //   } else {
    //     this.item = {
    //       isImport: this.params.isImport,
    //       isExport: this.params.isExport,
    //     };
    //     this.pivot = null;
    //   }
    },
    methods: {
        formattedRegions(type, item) {
            let regions = item.finChargeItemAreas.filter(x => x.type == type && x.regionId).map(x => x.regionId)
            return this.regions.filter(x => regions.includes(x.id)).map(x => x.name).length > 0 ? this.regions.filter(x => regions.includes(x.id)).map(x => x.name).length : null
        },
        formattedCountries(type, item) {
            let countries = item.finChargeItemAreas.filter(x => x.type == type && x.countryRegionId).map(x => x.countryRegionId)
            return this.countries.filter(x => countries.includes(x.id)).map(x => x.name).length > 0 ? this.countries.filter(x => countries.includes(x.id)).map(x => x.name).length : null
        },
    },
  };
  </script>
import { render, staticRenderFns } from "./ChargeDetails.vue?vue&type=template&id=18b47fde"
import script from "./ChargeDetails.vue?vue&type=script&lang=js"
export * from "./ChargeDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports